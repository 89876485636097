import React from 'react';
import { Grid, TextField, Typography, Button, FormHelperText } from '@material-ui/core';
import BuildingSelect from './BuildingSelect';

import find from 'lodash/find';
import { Formik } from 'formik';

import { cntInts, email_regexp, getDefaultStudentData, formatPhoneNumber } from '../../helpers/form_helpers';
import { isDefined, isBlank, isPresent, attemptParseInt } from '../../util/util';
import { store, getModels } from '../../constants/globals';
import { StudentFormErrors, StudentForm } from '../../types/form_types';
import SchoolYearSelect from './SchoolYearSelect';
import { findModelById } from '../../util/app_util';
import { getShortsFromCart } from '../../helpers/cart_helpers';
import uniq from 'lodash/uniq';

let phoneNeedsFormatting = false;

const not_required_fields = ['room_wing', 'building_id', 'room_number'];

const badprods: { [short: string]: string[] } = {
  BL: ['One Tribe Place', 'GGV - Lion', 'Tribe Square', 'Richmond Hall'],
  PM: ['One Tribe Place', 'Richmond Hall'],
};
const fullsized = ['Tribe Square', 'Richmond Hall'];

const validateProdsForBuilding = (building_id: number): string | null => {
  const building = findModelById(getModels('buildings'), building_id);

  const errors: string[] = [];
  const shorts = uniq(getShortsFromCart());

  shorts.forEach((s) => {
    const building_arr = badprods[s];

    if (building_arr && building_arr.includes(building.shown_name)) {
      if (s === 'BL') {
        if (fullsized.includes(building.shown_name)) {
          errors.push(`loft the full-sized beds at ${building.shown_name}`);
        } else {
          errors.push(`loft beds at ${building.shown_name}`);
        }
      } else if (s === 'PM') {
        errors.push(`deliver mattresses to ${building.shown_name}`);
      }
    }
  });

  if (errors.length) {
    const term = errors.length > 1 ? 'them' : 'it';
    const msgs = errors.join(' or ');

    return `We're sorry, but we cannot ${msgs}.  Please remove ${term} from your cart.  Apologies for the inconvenience!`;
  } else {
    return null;
  }
};

export type FormikType = {
  values: StudentForm;
  validateForm: () => void;
};

const StudentDetails = (): JSX.Element => {
  React.useEffect(() => {
    phoneNeedsFormatting = true;

    return (): void => {
      window.student_form = null;
    };
  }, []);

  const school = store.getNonNullState('school');
  return (
    <Formik
      innerRef={(instance: FormikType | null): void => {
        if (instance) {
          window.student_form = instance;
        }
      }}
      initialValues={getDefaultStudentData()}
      validate={(values): StudentFormErrors => {
        const errors: StudentFormErrors = {};
        if (!values.student_email) {
          errors.student_email = 'Required';
        } else if (!email_regexp.test(values.student_email)) {
          errors.student_email = 'Invalid email address';
        }

        if (/-/.test(values.room_number)) {
          errors.room_number = 'No dashes in your room number!';
        }

        if (/-/.test(values.room_wing)) {
          errors.room_wing = 'No dashes in your wing letter!';
        }

        if (attemptParseInt(values.building_id)) {
          const errorMsg = validateProdsForBuilding(parseInt(values.building_id));

          if (errorMsg) {
            errors.building_id = errorMsg;
          }
        }

        const phone_int_cnt = cntInts(values.student_phone);
        const phone_int_cnt_btwn_10_and_16 = phone_int_cnt >= 10 && phone_int_cnt <= 16;

        if (!phone_int_cnt_btwn_10_and_16) {
          errors.student_phone = 'Invalid phone number';
        }

        if (!values.student_first_name) {
          errors.student_first_name = 'Required';
        }

        if (!values.student_last_name) {
          errors.student_last_name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }): void => {
        window.App.studentDeets = values;
        store.setState('viewShown', 'ParentDetails');
      }}
    >
      {({ values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit }): JSX.Element => {
        const has_errors = Object.keys(errors).length > 0;

        const has_any_empty_values = isDefined(
          find(values, (val, key): boolean => {
            return !not_required_fields.includes(key) && !val;
          }),
        );

        if (phoneNeedsFormatting && isBlank(errors.student_phone) && isPresent(values.student_phone)) {
          values.student_phone = formatPhoneNumber(values.student_phone) || values.student_phone;
          phoneNeedsFormatting = false;
        }

        const btn_disabled = has_any_empty_values || has_errors || isSubmitting;

        const email_invalid = touched.student_email && isPresent(errors.student_email);
        const first_name_invalid = touched.student_first_name && isPresent(errors.student_first_name);
        const last_name_invalid = touched.student_last_name && isPresent(errors.student_last_name);
        const phone_invalid = touched.student_phone && isPresent(errors.student_phone);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const blurPhone = (e: React.FocusEvent<any>): void => {
          handleBlur(e);

          phoneNeedsFormatting = true;
        };

        return (
          <div className="mt-2 p-2">
            <Typography variant="h5" style={{ textAlign: 'center' }} className="mb-2">
              Student Details
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={first_name_invalid}
                  helperText={first_name_invalid ? errors.student_first_name : ''}
                  style={{ width: '100%' }}
                  className="mt-1"
                  name="student_first_name"
                  label="First name*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.student_first_name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={last_name_invalid}
                  helperText={last_name_invalid ? errors.student_last_name : ''}
                  className="mt-1"
                  style={{ width: '100%' }}
                  name="student_last_name"
                  value={values.student_last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Last name*"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={email_invalid}
                  helperText={
                    email_invalid
                      ? errors.student_email
                      : 'Please use your school assigned email address. If you do not have it yet, you may use your personal email address instead.  Also, please make sure that your email is typed correctly!'
                  }
                  className="mt-1"
                  style={{ width: '100%' }}
                  value={values.student_email}
                  name="student_email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Student email*"
                  type="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={phone_invalid}
                  helperText={phone_invalid ? 'invalid phone number' : null}
                  className="mt-1"
                  style={{ width: '100%' }}
                  value={values.student_phone}
                  onChange={handleChange}
                  onBlur={blurPhone}
                  label="Student phone number*"
                  name="student_phone"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <SchoolYearSelect year={values.year} onChange={handleChange} />
              </Grid>

              <Grid item xs={12}>
                <BuildingSelect school_id={school.id} building_id={values.building_id} onChange={handleChange} />
                {errors.building_id ? <FormHelperText error>{errors.building_id}</FormHelperText> : null}
              </Grid>

              {values.building_id !== 'null' && (
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!!errors.room_number}
                      helperText={errors.room_number ? errors.room_number : ''}
                      className="mt-3"
                      style={{ width: '100%' }}
                      value={values.room_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="room_number"
                      label="Your Room Number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="pb-4">
                    <TextField
                      className="mt-3"
                      style={{ width: '100%' }}
                      name="room_wing"
                      value={values.room_wing}
                      onChange={handleChange}
                      onBlur={blurPhone}
                      label="Wing/Suite Letter"
                      error={!!errors.room_wing}
                      helperText={
                        errors.room_wing
                          ? errors.room_wing
                          : "If you have a letter designation as part of your housing assignment (eg 'A'), please enter it here."
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </React.Fragment>
              )}

              <Grid item xs={12} className="my-3">
                <Button
                  style={{ width: '100%' }}
                  onClick={(): void => handleSubmit()}
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={btn_disabled}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      }}
    </Formik>
  );
};

export default StudentDetails;
